import { CommonModule } from "@angular/common";
import { SvgIconComponent } from "./svg-icon.component";
import { NgModule } from "@angular/core";
import { DataCopyWrapperDirective } from "../directives/formElements/dashboardTemplates/data-copy.wrapper.directive";
import { ProgressIndicatorComponent } from "./progress-indicator.component";
import { PageProgressIndicatorComponent } from "./page-progress-indicator.component";


@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        SvgIconComponent,
        DataCopyWrapperDirective,
        ProgressIndicatorComponent,
        PageProgressIndicatorComponent,
    ],
    exports: [
        SvgIconComponent,
        DataCopyWrapperDirective,
        ProgressIndicatorComponent,
        PageProgressIndicatorComponent
    ],
})
export class SharedComponentsModule { }