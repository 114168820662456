import { Component, Input } from "@angular/core";

/**
 * @description Copy of progress-indicator.directive.ts for angular2+ components, 
 * as angularjs components will have ended life cycle, this is better approach to not break old implementations.
 */
@Component({
    selector: 'progress-indicator',
    template: `
        <div class="progress-indicator" [ngClass]="{'progress-indicator--with-background' : withBackground}">
            <div class="progress-indicator-dot"></div>
            <div class="progress-indicator-dot"></div>
            <div class="progress-indicator-dot"></div>
        </div>
    `,
})
export class ProgressIndicatorComponent {

    @Input() withBackground?: boolean;

    constructor() { }
}