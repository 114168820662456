import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

@Directive({
    selector: 'copy-data',
})

export class DataCopyWrapperDirective extends UpgradeComponent{

    @Input() values;
    @Input() valuesLabel;
    @Input() separator;
    constructor(
        elementRef: ElementRef,
        injector: Injector,
    ) {
        super('copyData', elementRef, injector);
    }
}