import { Component, Input } from "@angular/core";

/**
 * @description Copy of page-progress-indicator.directive.ts for angular2+ components, 
 * as angularjs components will have ended life cycle, this is better approach to not break old implementations.
 */
@Component({
    selector: 'page-progress-indicator',
    template: `
        <div class="page-progress-indicator" [ngStyle]="{'padding-bottom': margin ? margin+'px' : '25px'}">
            <progress-indicator [withBackground]="true" />
        </div>
    `,
    styles: [`
        .page-progress-indicator {
            width: -webkit-fill-available;
            position: fixed;
        }
    `]
})
export class PageProgressIndicatorComponent {

    @Input() margin?: number;

    constructor() { }
}